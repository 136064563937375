/* Ordinary box */
.box {
  padding: 40px;
  background-color: #fff;
}

/* Making items full width */
.box .box-header,
.box .box-full-width {
  margin-left: -40px;
  margin-right: -40px;
}

.box .box-header + .box-full-width {
  margin-top: 0;
}

.box .box-full-width:last-child {
  margin-bottom: -40px;
}

.box .box-full-width:first-child,
.box .box-header:first-child {
  margin-top: -40px;
}

/* Headers */
.box-header {
  line-height: 1.2em;
  background-color: #E85C41;
  padding: .3em;
  padding-right: 40px;
  padding-left: calc(.9em + 40px + 20px);
  position: relative;
  color: #fff;
}

.box-header + * {
  margin-top: 20px;
}

.box-header:before {
  content: '';
  width: .9em;
  height: .9em;
  background: transparent url('../../img/icons/dotdotdot-white.svg') center center no-repeat;
  background-size: contain;
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  opacity: .6;
}

.box-header.box-header-parts:before {
  width: .8em;
  height: .8em;
  background-image: url('../../img/icons/brick-white.svg') ;
}

.box-header.box-header-instructions:before {
  width: .8em;
  height: .8em;
  background-image: url('../../img/icons/plates-white.svg') ;
}

.box-header.box-header-video:before {
  background-image: url('../../img/icons/videocamera-white.svg') ;
}

.box-header.box-header-gallery:before {
  background-image: url('../../img/icons/camera-white.svg') ;
}

.box-header.box-header-characters:before {
  background-image: url('../../img/icons/font-white.svg') ;
}

.box-header.box-header-similar:before {
  background-image: url('../../img/icons/dotdotdot-white.svg') ;
}

.box-header.box-header-techniques:before {
  background-image: url('../../img/icons/wrench-white.svg') ;
}