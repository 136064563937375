.table-of-contents {
  font-family: 'Nunito', sans-serif;
}

.table-of-contents > * {
  padding: 5px;
  padding-left: 20px;
}

.table-of-contents a {
  display: block;
  margin-top: 15px;
  font-size: 1.3rem;
  font-weight: 300;
  color: inherit;
  line-height: 1.2em;
}

.table-of-contents a.active,
.table-of-contents a:hover,
.table-of-contents a:focus {
  background-color: #373737;
  color: #E4AC15;
}

.table-of-contents a:first-child,
.table-of-contents .table-of-contents-sub-item {
  margin-top: 0;
}

.table-of-contents .table-of-contents-sub-item {
  font-size: 1rem;
}