.hero {
  position: relative;
  overflow: hidden;
  display: block;
}

.hero:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%);
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#bd000000',GradientType=0 );
}

.hero > * {
  position: relative;
  z-index: 3;
}

.hero-image {
  margin-left: auto;
  margin-right: auto;
  display: block;
  z-index: 1;
  max-height: 500px;
}

.hero-title {
  padding: 20px 40px;
  font-size: 4rem;
  font-weight: 300;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}

.hero-background {
  z-index: 1;
  position: absolute;
  top: -40px;
  bottom: -40px;
  left: -40px;
  right: -40px;
  background-color: #222;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-filter: blur(30px) saturate(50%);
  filter: blur(30px) saturate(50%);
}

.hero-dark {
	background-color: #222;
  text-align: center;
}

.hero-dark .hero-title {
  text-align: left;
}

.hero-dark img {
	border: 60px solid #fff;
	border-radius: 20px;
	margin: 60px;
  display: inline-block;
	background-color: #fff;
  max-width: calc(100% - 120px);
}

.hero-dark .hero-background {
	display: none;
}

a.hero:hover,
a.hero:focus {
  opacity: .8;
}