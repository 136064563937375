/* Footer */
.footer {
  color: #fff;
  padding: 60px 0;
  background-color: #373737;
}

.footer-first {
  background-color: #222;
  border-top: 10px solid #E85C41;
}

.footer-mailing-list {
  position: relative;
  font-size: 2rem;
}

.footer-mailing-list button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 3rem;
  padding-left: 3rem;
}

.footer-mailing-list input[type="email"] {
  background-color: rgba(255, 255, 255, .1);
  color: #fff;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
}

.footer-mailing-list input[type="email"]:focus {
  background-color: rgba(255, 255, 255, .07);
}

.footer-menu > * {
  display: inline-block;
  vertical-align: middle;
}

.footer-menu {
  font-family: 'Nunito', sans-serif;
  font-size: 0;
}

.footer-menu button,
.footer-menu a {
  color: #fff;
  font-size: 1rem;
  margin-right: 10px;
}

.footer-menu button:hover,
.footer-menu button:focus,
.footer-menu a:hover,
.footer-menu a:focus {
  color: #FFBF15;
}

.footer-menu .utility-links a:after {
  content: '•';
  margin-left: 10px;
  display: inline-block;
  opacity: .4;
  color: #fff !important;
}

.footer-menu .social button,
.footer-menu .social a {
  height: 1.4rem;
  width: 1.4rem;
  font-size: 0;
  padding: 0;
  background-color: transparent;
}


/* Footer bottom: story time */
:root.active-story-time {
  padding-bottom: 400px;
}

:root.active-story-time .story-time {
  display: block;
}

.story-time {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #373737 url('/img/crop/fullwidth/lego-minifigs-digging.jpg') center center no-repeat;
  background-size: cover;
  height: 400px;
  z-index: -1;
}

.story-time:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: .9;
  z-index: 1;
}

.story-time-intro {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 2;
}

.story-time-text {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 60px;
  color: #fff;
  opacity: 0;
  z-index: 2;
  font-size: 18px;
  max-width: 480px;
}

.story-time-text:after,
.story-time-text:before {
  content: '“';
  font-size: 20rem;
  font-weight: 800;
  position: absolute;
  top: 100px;
  left: 0;
  opacity: .3;
}

.story-time-text:after {
  content: '”';
  bottom: 0;
  right: 0;
  top: auto;
  left: auto;
}

.story-time:after,
.story-time-text,
.story-time-intro {
  transition: opacity 200ms ease-out 0ms;
}

.story-time:hover:after {
  opacity: .5;
}

.story-time:hover .story-time-intro {
  opacity: 0;
}

.story-time:hover .story-time-text {
  opacity: .9;
}