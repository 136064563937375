/* Centering */
.center-narrow,
.center {
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.center-narrow {
  width: 700px;
}


/* Main site layout */
main {
  position: relative;
  min-height: calc(100vh - 150px);
}

:root.swooshable-zen main {
  padding: 6vh 6vw;
  min-height: 0;
}

.primary-content {
  padding: 60px;
  padding-right: calc(360px + 60px);
}

.sidebar {
  background-color: rgba(0, 0, 0, .05);
  width: 380px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 60px 40px;
  overflow: hidden;
}


/* Columns */
[class*="columns-"] {
  overflow: hidden;
}

[class*="columns-"] > * {
  float: left;
  margin-left: 2%;
  margin-top: 1.5vw;
  min-height: 1px;
}

.columns-2 > * {
  width: 49%;
}

.columns-3 > * {
  width: 32%;
}

.columns-4 > * {
  width: 23.5%;
}

.columns-5 > * {
  width: 18.4%;
}

.columns-6 > * {
  width: 15%;
}

.columns-10 > * {
  width: 8.2%;
}

.columns-twothirds > * {
  width: 32%;

  &:first-child {
    width: 66%;
  }
}

.columns-onethird > * {
  margin-left: 8%;
  width: 26%;

  &:first-child {
    width: 66%;
  }
}

.columns-twothirds > *:nth-child(2n+1),
.columns-onethird > *:nth-child(2n+1),
.columns-2 > *:nth-child(2n+1),
.columns-3 > *:nth-child(3n+1),
.columns-4 > *:nth-child(4n+1),
.columns-5 > *:nth-child(5n+1),
.columns-6 > *:nth-child(6n+1),
.columns-10 > *:nth-child(10n+1) {
  clear: left;
  margin-left: 0;
}

.columns-twothirds > *:nth-child(-n+2),
.columns-onethird > *:nth-child(-n+2),
.columns-2 > *:nth-child(-n+2),
.columns-3 > *:nth-child(-n+3),
.columns-4 > *:nth-child(-n+4),
.columns-5 > *:nth-child(-n+5),
.columns-6 > *:nth-child(-n+6),
.columns-10 > *:nth-child(-n+10) {
  margin-top: 0;
}

/* Column modifiers */
.columns-equalheights {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.columns-onethird.columns-switch > * {
  float: right;
  margin-right: 8%;
  margin-left: 0;
  width: 66%;
}

.columns-onethird.columns-switch > *:first-child {
  width: 26%;
  margin-right: 0;
}


/* Spacing */
.search-result,
.search-overlay > *,
:root.swooshable-zen form > *,
:root.swooshable-zen main > *,
.primary-content > * {
  margin-top: 60px;
}

.sidebar > *,
.tab-content > * > *,
.box > *,
.box > .box-header + * {
  margin-top: 40px;
}

form > *,
.footer .center > *,
.secondary-content > * {
  margin-top: 20px;
}

.small-spacing > *,
.search-item,
.preview label ~ *,
label + *,
.description {
  margin-top: 5px;
}

.small-spacing > *:first-child,
.search-overlay > *:first-child,
:root.swooshable-zen form > *:first-child,
:root.swooshable-zen main > *:first-child,
form > *:first-child,
.tab-content > * > *:first-child,
.section > *:first-child,
.footer .center > *:first-child,
.secondary-content > *:first-child,
.box > *:first-child,
.primary-content > *:first-child,
.sidebar > *:first-child {
  margin-top: 0;
}