.filter {
  background-color: #fff;
  padding: 30px;
}

.tags {
  margin-right: -2px;
  margin-bottom: -2px;
  font-size: 0;
}

.tags a,
.tags button {
  font-size: .8rem;
  display: inline-block;
  border-radius: 0;
  margin-right: 2px;
  margin-bottom: 2px;
  padding: .8em 1.4em;
  background-color: #52BAD5;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  line-height: normal;
}

.filter .tags a,
.filter .tags button {
  background-color: #222;
}

.filter .tags .active {
  background-color: #52BAD5;
}

.tags a:hover,
.tags button:hover,
.tags a:focus,
.tags button:focus {
  background-color: #57C6E3;
}