.character {
  display: inline-block;
  height: 40px;
  position: relative;
}

.character img {
  height: 100%;
  width: auto;
}

.character-detail {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -5px);
  border-radius: 100%;
  background-color: #fff;
  color: #222;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 1px solid #8f8f8f;
}

.character:hover {
  background-color: #FEBE12;
}

.character:hover .character-detail {
  display: block;
}

.character:hover img {
  opacity: .4;
}