.data-pair {
  margin-bottom: -2px;
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: .8rem;
}

.data-pair > * {
  padding: 7px 10px;
  background-color: #52BAD5;
  color: #fff;
  margin-right: 1px;
  margin-bottom: 1px;
  margin-top: 0 !important;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  vertical-align: top;
  line-height: 1.3em;
}

.data-pair > * a {
  display: block;
  color: #fff;
}

.data-pair .data-pair-key {
  width: calc(36% - 1px);
  font-weight: 400;
}

.data-pair .data-pair-value {
  width: 64%;
  margin-right: 0;
}

.data-pair a:hover,
.data-pair a:focus {
  color: #FEBE12;
}