/* TABLES 
======================= */
table {
  width: 100%;
  font-size: .8rem;
}

tr {
  border-bottom: 1px solid #898989;
}

th,
td {
  padding: 5px;
  line-height: 1.4em;
  vertical-align: middle;
}

th {
  text-align: left;
  vertical-align: bottom;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
}

th[data-sort] {
  position: relative;
  padding-right: 20px;
  cursor: pointer;
}

th[data-sort]:before,
th[data-sort]:after {
  content: '';
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: transparent url('../../img/icons/arrow-down.svg') center center no-repeat;
  background-size: 100% 100%;
  height: 10px;
  width: 10px;
  opacity: .2;
}

th[data-sort]:before {
  bottom: 13px;
  transform: rotate(180deg);
}

th[data-sort]:hover:before,
th[data-sort]:focus:before,
th[data-sort]:hover:after,
th[data-sort]:focus:after {
  opacity: .5;
}

th[data-sort].sorting-desc:before,
th[data-sort].sorting-asc:after {
  opacity: .8;
}

/* Color reference table */
table.color-reference .color-reference-hex {
  vertical-align: middle;
  text-align: center;
  border-left: 1px solid #898989;
  border-right: 1px solid #898989;
  line-height: 1em;
}

table.color-reference .color-reference-hex span {
  display: inline-block;
  background: rgba(255, 255, 255, .5);
  color: #000;
  padding: 3px 5px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
}

table.color-reference tr[data-colorid]:hover,
table.color-reference tr[data-colorid]:focus {
  background-color: rgba(0, 0, 0, .1);
  cursor: pointer;
}
