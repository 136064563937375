/* DOCUMENT VIEWER  & IMAGE VIEWER
======================= */
.viewer iframe {
  width: 100%;
  height: 1000px;
}

.viewer[tabindex="1"] {
  font-size: 4em;
  font-family: Nunito, sans-serif;
  font-weight: 300;
  color: rgba(0, 0, 0, .7);
  background: rgba(0, 0, 0, .1);
  background-size: 200px;
  text-align: center;
  padding: 150px 0;
  overflow: hidden;
  position: relative;
  border: 10px solid rgba(0, 0, 0, .05);
}

.viewer[tabindex="1"]:hover,
.viewer[tabindex="1"]:focus {
  background-color: rgba(0, 0, 0, .05);
  border-color: rgba(0, 0, 0, .1);
  background-size: 350px;
  cursor: pointer;
}

.viewer-navigation{
  font-size: 0;
}

.viewer-navigation > * {
  display: inline-block;
  vertical-align: top;
  opacity: .5;
  width: 10%;
  padding-bottom: 10%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.viewer-navigation> *:hover,
.viewer-navigation> *:focus,
.viewer-navigation> *.active {
  opacity: 1;
}

.viewer-intro {
  position: relative;
  z-index: 2;
}

.viewer-background {
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: -10px;
  left: -10px;
  bottom: -10px;
  right: -10px;
  filter: grayscale(1);
  opacity: .1;
}