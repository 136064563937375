/* Adjusting main layout */
/* Sidebar ads are 300px wide as default */
@media only screen and (min-width: 980px) and (max-width: 1580px) {

  .center {
    width: 900px;
  }
}

@media only screen and (max-width: 1380px) {

  /* Ads that are 160px wide */
  .primary-content {
    padding-right: calc(200px + 60px);
  }

  .sidebar {
    width: 200px;
  }
}

@media only screen and (max-width: 1220px) {

  .sidebar {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    width: 160px;
  }

  .primary-content {
    padding-right: calc(160px + 40px);
    padding-left: 40px;
  }

  .center {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }

  .header .social {
    display: none;
  }
}


@media only screen and (max-width: 980px) {

  .center {
    margin-left: 40px;
    margin-right: 40px;
  }

  .primary-content {
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar {
    position: static;
    width: auto;
    padding: 0 40px 20px;
    background-color: transparent;
  }
}


@media only screen and (max-width: 925px) {

  .menu-small {
    display: block;
  }

  html.show-menu,
  html.show-menu body {
    overflow-y: hidden;
  }

  .header .menu {
    display: none;
  }
}

@media only screen and (max-width: 900px) {

  .footer,
  .primary-content {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .primary-content > * {
    margin-top: 40px;
  }

  .sidebar {
    padding-left: 40px;
    padding-right: 40px;
  }

  .calendar-filter [class*="columns-"] {
    padding-left: 80px;
    padding-right: 80px;
  }

  .calendar-filter > a.button {
    padding: 1rem 1.4rem;
  }
}

@media only screen and (max-width: 780px) {

  .center-narrow {
    width: auto;
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media only screen and (max-width: 550px) {

  .sidebar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .center {
    margin-left: 20px;
    margin-right: 20px;
  }

  .box {
    padding: 20px;
  }

  .box .box-header,
  .box .box-full-width {
    margin-left: -20px;
    margin-right: -20px;
  }

  .box .box-full-width:first-child,
  .box .box-header:first-child {
    margin-top: -20px;
  }

  .sidebar > *,
  .tab-content > * > *,
  .box > *,
  .box > .box-header + * {
    margin-top: 20px;
  }
}


/* Grids */
@media only screen and (max-width: 1040px) {

  .columns-6 > * {
    width: 23.5%;
  }

  .columns-6 > *:nth-child(-n+6) {
    margin-top: 2%;
  }

  .columns-6 > *:nth-child(6n+1) {
    margin-left: 2%;
    float: left;
  }

  .columns-6 > *:nth-child(-n+4) {
    margin-top: 0;
  }

  .columns-6 > *:nth-child(4n+1) {
    clear: left;
    margin-left: 0;
  }
}

@media only screen and (max-width: 660px) {

  .faux-label {
    display: none;
  }

  .columns-2 > *,
  .columns-3 > * {
    width: auto;
    float: none;
  }

  .columns-4 > *,
  .columns-6 > * {
    width: 49%;
  }

  .columns-2 > *:nth-child(-n+2),
  .columns-3 > *:nth-child(-n+3),
  .columns-4 > *:nth-child(-n+4) {
    margin-top: 20px;
  }

  .columns-4 > *:nth-child(-n+4),
  .columns-6 > *:nth-child(-n+4) {
    margin-top: 2%;
  }

  .columns-4 > *:nth-child(4n+1),
  .columns-6 > *:nth-child(4n+1) {
    margin-left: 2%;
    float: left;
  }

  .columns-2 > *:first-child,
  .columns-3 > *:first-child,
  .columns-4 > *:nth-child(-n+2),
  .columns-6 > *:nth-child(-n+2) {
    margin-top: 0;
  }

  .columns-2 > *,
  .columns-3 > *,
  .columns-4 > *:nth-child(2n+1),
  .columns-6 > *:nth-child(2n+1) {
    clear: left;
    margin-left: 0;
  }
}

@media only screen and (max-width: 430px) {

  .calendar-filter > a.button {
    display: none;
  }

  .calendar-filter [class*="columns-"] {
    padding-left: 0;
    padding-right: 0;
  }
}



/* Typography and other text adjustments */
@media only screen and (max-width: 780px) {

  .columns-onethird > *:nth-child(-n+2),
  .columns-onethird.columns-switch > *:first-child,
  .columns-onethird.columns-switch > *,
  .columns-onethird > *:first-child,
  .columns-onethird > * {
    width: auto;
    float: none;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
  }

  .columns-onethird.columns-switch > *:first-child,
  .columns-onethird > *:first-child {
    margin-top: 0;
  }

  .is_stuck {
    position: static !important;
    width: auto !important;
  }

  .is_stuck + * {
    display: none !important;
  }

  .table-of-contents a {
    padding-left: 0;
    padding-right: 0;
  }

  .table-of-contents a:hover,
  .table-of-contents a:focus {
    background-color: transparent;
  }

  .table-of-contents a.active:not(:hover):not(:focus) {
    background-color: inherit;
    color: inherit;
  }

  .world-map .map-canvas {
    height: 300px !important;
  }

  .hero-dark .hero-title,
  .hero-title {
    position: relative;
    text-align: center;
  }

  .hero-dark .hero-image {
    border-width: 20px;
    margin: 40px;
  }

  .hero-dark .hero-title {
    margin-top: -40px;
  }

  .vertical-tabs > .tab-content,
  .vertical-tabs > .tabs {
    width: auto;
    float: none;
    text-align: left;
  }

  .vertical-tabs > .tabs {
    border-bottom: 10px solid rgba(0, 0, 0, .4);
    border-right: 0;
    font-size: 0;
  }

  .vertical-tabs .tabs a,
  .vertical-tabs .tabs button {
    width: auto;
    display: inline-block !important;
    font-size: 1rem;
    padding: .7em 1em;
  }

  .vertical-tabs:after {
    display: none;
  }
}

@media only screen and (max-width: 660px) {
  :root {
    font-size: 14px;
  }

  .legible h2,
  .header-2 {
    font-size: 2em;
  }

  .hero-title {
    font-size: 2.25rem;
  }

  .footer-mailing-list {
    font-size: 1.4rem;
  }

  .footer-menu {
    font-size: 0;
  }

  .footer-menu > * {
    display: inline;
  }

  .footer-menu a {
    display: inline-block;
    padding: .75em 1.5em;
    background-color: rgba(0, 0, 0, .2);
    border-radius: .2em;
    font-size: 1rem;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .footer-menu .utility-links a:after {
    display: none;
  }

  .footer-menu .social a {
    font-size: 1rem;
    color: transparent;
    overflow: hidden;
    height: auto;
    width: 1em;
  }

  .footer-menu .social a:before {
    width: 60%;
    height: 60%;
  }
}

@media only screen and (max-width: 500px) {
  .footer-mailing-list {
    font-size: 1rem;
  }

  .footer-mailing-list input[type="email"] {
    border-radius: 0;
  }

  .footer-mailing-list button {
    position: static;
    width: 100%;
    margin-top: 10px;
  }
}

/* Calendar */
@media only screen and (max-width: 800px) {
  .calendar-date {
    font-size: 1rem;
  }

  .calendar th {
    font-size: .8rem;
  }

  .calendar th .day-not-short {
    display: none;
  }

  .calendar-date,
  .calendar th {
    padding: 5px;
  }

  .calendar td a {
    padding: 5px;
    font-size: .6rem;
    line-height: 1.2em;
  }
}

@media only screen and (max-width: 500px) {
  .calendar td a {
    font-size: .4rem;
  }

  .header .site-name {
    width: 0;
    overflow: hidden;
    color: transparent;
  }
}