/* Calendar table */
.calendar {
  table-layout: fixed;
}

.calendar th,
.calendar td {
  vertical-align: top;
  border: 0;
  background-color: #2C9AB7;
  color: #fff;
  border-left: 1px solid #efefef;
  padding: 1px;
}

.calendar td.calendar-not-current-month {
  background-color: #d6d6d6;
}

.calendar td.calendar-today {
  background-color: #222;
}

.calendar th {
  padding: 10px;
  font-size: 1rem;
  color: rgba(255, 255, 255, .5);
}

.calendar tr {
  border-top: 1px solid #efefef;
  border-bottom: 0;
}

.calendar th:first-child,
.calendar td:first-child {
  border-left: 0;
}

.calendar thead tr:first-child {
  border-top: 0;
}

.calendar td > * {
  display: block;
  margin-top: 1px;
}

.calendar-date {
  color: rgba(255, 255, 255, .3);
  margin-top: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 2rem;
  line-height: 1em;
  padding: 10px;
}

.calendar td a {
  color: #373737;
  background-color: #FEBE12;
  padding: 5px 10px;
  font-family: 'Nunito', sans-serif;
  overflow: hidden;
}

.calendar td a:hover,
.calendar td a:focus {
  background-color: #FED156;
}

/* Calendar filter */
.calendar-filter {
  position: relative;
}

.calendar-filter [class*="columns-"] {
  padding-left: 120px;
  padding-right: 120px;
  margin-top: 0;
}

.calendar-filter > a.button {
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  top: 50%;
  right: 30px;
  margin-top: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.calendar-filter > a.button:first-child {
  right: auto;
  left: 30px;
}