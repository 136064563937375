.teaser {
  display: block;
  background-color: #fff;
  position: relative;
  border: 1px solid #8f8f8f;
  color: #2d2d2d;
  border-radius: .3em;
  overflow: hidden;
}

.teaser-image {
  height: 150px;
  overflow: hidden;
  border-bottom: 0;
  position: relative;
	background-color: #fff;
}

.teaser-image.teaser-image-blue {
  background-color: #52BAD5;
}

.teaser-image.teaser-image-black {
  background-color: #5D5C5D;
}

.teaser-image.teaser-image-red {
  background-color: #E85C41;
}

.teaser-image.teaser-image-yellow {
  background-color: #FED156;
}

.teaser-image:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 25%;
  left: 0;
  right: 0;
  opacity: .4;
  z-index: 1;
  background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
}

.teaser-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.teaser-content {
  border-top: 1px solid #8f8f8f;
  padding: 8%;
}

.teaser-content > * {
  margin-top: 10px;
}

.teaser-content > *:first-child {
  margin-top: 0;
}

.teaser-content-title {
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  line-height: 1.3em;
  font-weight: 400;
}

.metadata {
  opacity: .8;
}

a.teaser:hover,
a.teaser:focus {
  text-decoration: none;
  background-color: #efefef;
  color: inherit;
}

a.teaser:hover .teaser-image:before,
a.teaser:focus .teaser-image:before {
  opacity: 0;
}

.teaser.part-teaser {
	text-align: center;
	border: 0;
}

.teaser.part-teaser .teaser-content {
	border-top: 0;
}

.teaser.part-teaser .teaser-image:before {
	display: none;
}

.teaser.part-teaser .teaser-image img {
	width: 100%;
	height: auto;
	object-fit: contain;
	position: absolute;
	bottom: 0;
	left: 0;
}