label {
  display: block;
  font-family: 'Nunito', sans-serif;
  line-height: 1.2em;
  font-size: .8em;
}

.description {
  text-align: right;
  opacity: .6;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

select {
  background-image: url('../../img/icons/arrow-down.svg');
  background-position: 95% center;
  background-repeat: no-repeat;
  background-size: 1em 1em;
}

select,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="telephone"],
textarea {
  padding: 1rem 1.4rem;
  border: 0;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  background-color: rgba(0, 0, 0, .07);
}

input[type="range"] {
  border: 0;
}

select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="telephone"]:focus,
textarea:focus {
  background-color: rgba(0, 0, 0, .1);
}

.pagination a,
.button,
input[type="submit"],
button {
  padding: 1rem 1.4rem;
  border: 0;
  display: inline-block;
  color: #373737;
  background-color: #FEBE12;
  font-family: 'Nunito', sans-serif;
  text-align: center;
  border-radius: 3em;
  line-height: 1.3em;
  font-weight: 400;
}

.pagination a:hover,
.pagination a:focus,
.button:hover,
.button:focus,
input[type="submit"]:hover,
input[type="submit"]:focus,
button:hover,
button:focus {
  text-decoration: none;
  background-color: #FED156;
  color: #373737
}

.button-wide {
  display: block;
  width: 100%;
}

.button-large {
  padding: 1em 2em;
  font-size: 1.4em;
}

[disabled] {
  opacity: 0.7;
}

.image-preview {
  text-align: center;
}

.embed-preview iframe {
  max-height: 150px
}

.property .image-preview {
  text-align: left;

  img {
    max-height: 80px;
  }
}