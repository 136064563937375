.administer-item {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transform: translateY(100%);
  background-color: rgba(0, 0, 0, .9);
  margin-top: 0;
  color: #fff;
  z-index: 200;
  transition: opacity 200ms ease-out 0ms, transform 200ms ease-out 0ms;
}

:root.show-administer-item .administer-item {
  opacity: 1;
  transform: translateY(0);
}

.administer-item-content {
  position: absolute;
  max-width: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.administer-item-toggle {
  position: fixed;
  bottom: 0;
  right: 0;
  border-radius: 0;
  z-index: 201;
}