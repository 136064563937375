.pagination {
  font-size: 0;
  text-align: center;
  margin-bottom: -5px;
  margin-left: -5px;
}

.pagination li {
  display: inline-block;
  font-size: 1rem;
  vertical-align: middle;
  margin-left: 5px;
  margin-bottom: 5px;
}

.pagination span,
.pager-ellipsis,
.pagination a {
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.1rem;
  display: block;
  font-family: inherit;
  font-weight: 700;
  border-radius: 0 !important;
  padding: 0 !important;
}

.pagination span {
  background-color: #fff;
}