/* BASICS ---------------- */
:root {
  color: #222;
  font-family: 'Lora', serif;
}

:root {
  font-size: 16px;
}

:root,
body {
  background-color: #efefef;
}

a {
  text-decoration: none;
  color: #E4AC15;
}

a:focus,
a:hover {
  text-decoration: none;
  color: #FFBF15;
}

* {
  outline: 0 !important;
}

::-moz-selection {
  background: #b3d4fc;
  background: rgba(0, 0, 0, 0.2);
}

::selection {
  background: #b3d4fc;
  background: rgba(0, 0, 0, 0.2);
}

.wireframe {
  background-color: #222;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  padding: 20px;
  color: #fff;
}

.note {
  opacity: .5;
}