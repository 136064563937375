/* Sizes and spacing */
.legible {
  line-height: 1.8em;
  font-size: 1rem;
}

.legible h1,
.legible h2,
.legible h3,
.legible h4,
.legible h5,
.legible h6,
.legible div,
.legible p,
.legible ul,
.legible ol,
.legible blockquote,
.legible table,
.legible form,
.legible pre code:first-child {
  margin-top: 1.5em;
}

.legible *:first-child {
  margin-top: 0;
}

.header-1 + *,
.header-2 + *,
.header-3 + *,
.header-4 + *,
.header-5 + *,
.header-6 + *,
.legible h1 + *,
.legible h2 + *,
.legible h3 + *,
.legible h4 + *,
.legible h5 + *,
.legible h6 + * {
  margin-top: 0.3em;
}

.legible form {
  display: block;
}

/* Headers */
.header-1,
.legible .header-1,
.legible h1 {
  font-size: 3.375em;
  line-height: 1.3em;
  font-weight: 400;
  font-family: 'Nunito', sans-serif;
}

.header-2,
.legible .header-2,
.legible h2 {
  font-size: 2.25em;
  line-height: 1.3em;
  font-weight: 400;
  font-family: 'Nunito', sans-serif;
}

.header-3,
.legible .header-3,
.legible h3 {
  font-size: 1.5em;
  line-height: 1.3em;
  font-weight: 400;
  font-family: 'Nunito', sans-serif;
}

.header-4,
.legible .header-4,
.legible h4 {
  font-weight: 400;
  font-family: 'Nunito', sans-serif;
  font-size: 1.2em;
  line-height: 1.3em;
}

.header-5,
.legible .header-5,
.legible h5 {
  font-weight: 300;
  font-family: 'Nunito', sans-serif;
  font-size: 1.2em;
  line-height: 1.3em;
}

.header-6,
.legible .header-6,
.legible h6 {
  font-family: 'Nunito', sans-serif;
  font-size: 1.2em;
  line-height: 1.3em;
}

/* Lists */
.legible ul,
.legible ol {
  list-style-position: outside;
  margin-left: 1.5em;
  padding-left: 0;
}

.legible ul {
  list-style-type: disc;
}

.legible ol {
  list-style-type: decimal;
}

/* Quotes/bolds/italics and other emphasis */
.legible b,
.legible strong {
  font-weight: 700;
}

.legible i,
.legible em {
  font-style: italic;
}

.legible code {
  vertical-align: bottom;
  font-family: 'Monaco', Courier, 'Courier New', monospace;
  background-color: #222;
  color: #fff;
}

.legible pre code {
  display: block;
}

.legible blockquote {
  font-size: 1.2em;
  line-height: 1.3em;
  font-weight: 700;
  font-style: italic;
  margin-left: 1.5em;
  padding: 0em;
  color: inherit;
  background-color: transparent;
}