/* Header */
.header {
  height: 110px;
  background-color: #373737;
  border-bottom: 10px solid #E85C41;
  position: relative;
}

.header button,
.header a {
  line-height: 100px;
  padding: 0 10px;
  display: block;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  background-color: transparent;
}

.header button:hover,
.header button:focus,
.header a:hover,
.header a:focus {
  background-color: rgba(0, 0, 0, .4);
  text-decoration: none;
}

.site-name {
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 90px !important;
  padding-right: 30px !important;
}

.site-name:before {
  content: '';
  width: 37px;
  height: 65px;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent url('../../img/logo-swooshable-white.svg') center center no-repeat;
  background-size: contain;
}


/* Menu */
.header .menu {
  position: absolute;
  top: 0;
  left: 310px;
  right: 0;
  font-size: 0;
}

.header .menu .menu-item {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.header .menu-item > a {
  padding: 0 15px;
  display: block;
}

.header .menu a {
  font-size: 16px;

}

.header .sub-menu {
  position: absolute;
  top: 100%;
  left: -10px;
  width: 250px;
  display: none;
  background-color: #373737;
  z-index: 10;
  border: 10px solid #E85C41;
  border-top: 0;
}

.header .sub-menu a {
  line-height: 1.5em;
  padding: 15px 25px;
  text-transform: none;
}

.header .menu-item:hover > .sub-menu,
.header .menu-item:focus > .sub-menu {
  display: block;
}


/* social */
.header .social {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
}

.header .social button,
.header .social a {
  width: 60px;
  height: 100px;
}

.header .social button:before,
.header .social a:before {
  height: 40%;
  width: 40%;
}

.social {
  font-size: 0;
}

.social button,
.social a {
  display: inline-block;
  vertical-align: top;
  width: 1rem;
  height: 1rem;
  position: relative;
}

.social button:before,
.social a:before {
  opacity: .4;
  content: '';
  height: 100%;
  width: 100%;
  background: transparent url('../../img/social/flickr-white.svg') center center no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social button:hover:before,
.social button:focus:before,
.social a:hover:before,
.social a:focus:before {
  opacity: 1;
}

.social a.social-twitter:before {
  background-image: url('../../img/social/twitter-white.svg');
}

.social a.social-youtube:before {
  background-image: url('../../img/social/youtube-white.svg');
}

.social a.social-pinterest:before {
  background-image: url('../../img/social/pinterest-white.svg');
}

.social button.toggle-search:before {
  background-image: url('../../img/icons/search-white.svg');
  background-size: 85%;
}


/* Small menu */
$small_menu_width: 240px;

.menu-small {
  display: none;
  font-family: 'Nunito', sans-serif;
}

.menu-toggle {
  width: 80px;
  height: 70px;
  font-family: 'Nunito', sans-serif;
  padding: 0;
  padding-right: 60px !important;
  position: absolute;
  top: 15px;
  right: 0;
  left: auto;
  z-index: 151;
  display: inline-block;
  background: transparent;
  color: white;
  background: #373737;
  border-radius: 0;
  transform: translateX(0);
  transition: transform 250ms ease-out 0s;
}

.menu-toggle span {
  display: block;
  width: 0;
  overflow: hidden;
}

.menu-toggle span:after,
.menu-toggle span:before,
.menu-toggle:after {
  content: ' ';
  position: absolute;
  display: block;
  width: 39px;
  height: 4px;
  top: 50%;
  margin-top: -2px;
  right: 20px;
  background: white;
  transition: background 150ms ease-out 0s,
              transform 150ms ease-out 0s;
}

.menu-toggle span:before,
.menu-toggle span:after {
  margin-top: 0;
  top: 20px;
  transform: rotate(0deg);
  transform-origin: top right;
}

.menu-toggle span:after {
  top: auto;
  bottom: 20px;
  transform-origin: bottom right;
}


.menu-toggle:hover,
.menu-toggle:focus {
  color: #FEBE12;
  background-color: transparent;
}

html.show-menu .menu-toggle:hover span:before,
html.show-menu .menu-toggle:focus span:before,
html.show-menu .menu-toggle:hover span:after,
html.show-menu .menu-toggle:focus span:after,
.menu-toggle:hover:after,
.menu-toggle:focus:after,
.menu-toggle:hover span:before,
.menu-toggle:focus span:before,
.menu-toggle:hover span:after,
.menu-toggle:focus span:after {
  background: #FEBE12;
}

html.show-menu .menu-toggle {
  color: transparent;
  background: transparent;
  transform: translateX(-$small_menu_width);
}

html.show-menu .menu-toggle:after {
  background: transparent;
}

html.show-menu .menu-toggle span:after,
html.show-menu .menu-toggle span:before {
  background: white;
  transform: rotate(-45deg);
}

html.show-menu .menu-toggle span:after {
  transform: rotate(45deg);
}

.menu-small-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: .7;
  z-index: 150;
  transform: translateX(100%);
  transition: transform 250ms ease-out 0ms;
}

html.show-menu .menu-small-overlay {
  transform: translateX(0);
}

.menu-small-content {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: $small_menu_width;
  background-color: #373737;
  z-index: 160;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border-left: 5px solid #E85C41;
  transform: translateX($small_menu_width);
  transition: transform 250ms ease-out 0ms;
}

html.show-menu .menu-small-content {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.menu-small-content a {
  padding: 15px 20px;
  font-weight: 700;
  display: block;
  color: white;
  background-color: #E85C41;
}
.menu-small-content a:hover,
.menu-small-content a:focus {
  background-color: #C24C36;
}

.menu-small-content a.active {
  color: inherit;
}

.menu-small-content .sub-menu a {
  padding: 15px 20px;
  font-weight: 300;
  background-color: transparent;
}

.menu-small-content .sub-menu a:hover,
.menu-small-content .sub-menu a:focus {
  background-color: rgba(0, 0, 0, .4);
}