/* NOTES 
======================= */
[data-note] {
  position: relative;
  cursor: help;
}

[data-note]:before {
  content: attr(data-note);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .7);
  position: absolute;
  bottom: calc(100%);
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  width: 300px;
  line-height: 1.4em;
  display: none;
}

[data-note]:hover:before,
[data-note]:focus:before,
[data-note].active:before {
  display: block;
}