.keyboard-key {
  display: inline-block;
  text-transform: uppercase;
  padding: .6em 1em;
  border: 2px solid currentColor;
  border-radius: .5em;
  color: #E4AC15;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: .8em;
  line-height: normal;
}