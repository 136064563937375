$search_animation: 150ms;

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 6vw;
  z-index: 200;
  background-color: rgba(#efefef, 1);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  visibility: hidden;
  transition: opacity $search_animation ease-out 0ms, visibility 0ms ease-out $search_animation;
}

:root.show-search {
  overflow: hidden;

  body {
    overflow: hidden;
  }

  .search-overlay {
    opacity: 1;
    visibility: visible;
    transition: opacity $search_animation ease-out 0ms, visibility 0ms ease-out 0ms;
  }
}

.search-overlay .toggle-search {
  position: absolute;
  top: 20px;
  right: 20px;
  margin-top: 0;
}

.search-form {
  position: relative;

  input[type="text"] {
    background-color: transparent;
    font-size: 5rem;
    color: #E85C41;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    padding: 0;
    padding-right: 100px;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: 0;
    background: transparent url('../../img/icons/search-black.svg') right center no-repeat;
    background-size: 60%;
    color: transparent;
    border-radius: 0;
    padding: 0;
    opacity: .4;
    width: 100px;

    &:hover,
    &:focus {
      opacity: .6;
    }
  }
}

.search-results {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: -20px;
  margin-right: -20px;
  flex-wrap: wrap;
  flex-direction: row;

  > * {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  > .search-direct-links {
    flex-basis: 100%;
  }
}

.search-item {
  display: block;
  border-radius: 5px;
  padding: 20px;
  background-color: rgba(118, 117, 128, 0.05);
  color: #2d2d2d;
  font-family: Nunito, sans-serif;
  font-size: .8rem;
  position: relative;
  line-height: 1.3em;

  &:hover,
  &:focus {
    background-color: #FED156;
    color: #2d2d2d;
  }

  > * {
    margin-top: 5px;
  }

  > *:first-child {
    margin-top: 0;
  }
}

.search-item .search-item-image {
  width: 29px;
  height: 29px;
  overflow: hidden;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ~ * {
    margin-left: 49px;
  }

  + * {
    margin-top: 0;
  }
}

.search-hints {
  display: flex;

  > * {
    padding-right: 60px;
  }

  > *:last-child {
    padding-right: 0;
  }
}

.search-shortcuts p {
  white-space: nowrap;
}