.vertical-tabs {
  overflow: hidden;
  background-color: #373737;
  position: relative;
  /*border-bottom: 20px solid #373737;
  border-top: 20px solid #373737;*/
}

.vertical-tabs > * {
  float: left;
}

.vertical-tabs > .tabs {
  width: 22%;
  border-right: 10px solid transparent;
}

.vertical-tabs:after {
  content: '';
  width: 10px;
  background-color: rgba(0, 0, 0, .4);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 78%;
}

.vertical-tabs > .tab-content {
  width: 78%;
  background-color: #fff;
}

.tab-content {
  padding: 40px;
}

.vertical-tabs.box-full-width .tab-content {
  padding-right: 40px;
}

.vertical-tabs .tabs a,
.vertical-tabs .tabs button {
  font-weight: 300;
  padding: 20px;
  display: block !important;
  background: transparent;
  color: white;
  text-align: right;
  transition: none;
  border-radius: 0 !important;
  width: 100%;
}

.vertical-tabs .tabs a.active,
.vertical-tabs .tabs button.active {
  cursor: default;
}

.vertical-tabs .tabs a.active,
.vertical-tabs .tabs button.active,
.vertical-tabs .tabs a.active:hover,
.vertical-tabs .tabs a.active:focus,
.vertical-tabs .tabs button.active:hover,
.vertical-tabs .tabs button.active:focus,
.vertical-tabs .tabs a:hover,
.vertical-tabs .tabs a:focus,
.vertical-tabs .tabs button:hover,
.vertical-tabs .tabs button:focus  {
  background-color: rgba(0, 0, 0, .4);
}